import axios from '../../../../js/axios';
import { emailRegEx } from '../../../../js/utils/regex';
import contact from '../../../../_data/contact';
import { getCaptcha } from '../../../../js/services/captchaService';
import LoadingBackdropService from '../../../../js/services/loadingBackdropService';
import MessageService from '../../../../js/services/messageService';

let captcha;
window.refreshCaptcha = async () => {
    captcha = await getCaptcha();
    const captchaElement = document.getElementById('captchaImg').src = captcha.image;
};

refreshCaptcha();

let contactFormData = {
    option: "project",
    firstname: {
        value: undefined,
        valid: null
    },
    lastname: {
        value: undefined,
        valid: null
    },
    email: {
        value: undefined,
        valid: null
    },
    phone: {
        value: undefined,
        valid: null
    },
    captcha: {
        value: undefined,
        valid: null
    },
    message: {
        value: undefined,
        valid: null
    },
    consent: false
};

const isContactFormValid = () => {
    if (
        contactFormData.firstname.valid &&
        contactFormData.lastname.valid &&
        contactFormData.email.valid &&
        contactFormData.phone.valid &&
        contactFormData.message.valid &&
        contactFormData.captcha.valid &&
        contactFormData.consent === true
    ) {
        document.getElementById('contact-form-submit').disabled = false;
    } else {
        document.getElementById('contact-form-submit').disabled = true;
    }
};

isContactFormValid();

window.handleOption = (type) => {
    contactFormData.option = type;
};

window.handleConsent = (e) => {
    contactFormData.consent = e.target.checked;
    isContactFormValid();
};

const fieldValidation = (e, lang) => {
    contactFormData[`${e.target.name}`].valid = e.target.value.length > 0;
    contactFormData[`${e.target.name}`].value = e.target.value;
    const helpElement = document.getElementById(`${e.target.name}Help`);
    if (e.target.value.length > 0) {
        if (e.target.name === "email") {
            contactFormData[`${e.target.name}`].valid = emailRegEx.test(e.target.value);
            if (emailRegEx.test(e.target.value)) {
                helpElement.innerHTML = "";
            } else {
                helpElement.innerHTML = contact[`${lang}`].emailHelperInvalid;
            }
        } else if (e.target.name === "captcha") {
            contactFormData[`${e.target.name}`].valid = e.target.value === captcha.text;
            if (e.target.value === captcha.text) {
                helpElement.innerHTML = "";
            } else {
                helpElement.innerHTML = contact[`${lang}`].captchaHelper;
            }
        } else {
            helpElement.innerHTML = "";
        }
    } else {
        helpElement.innerHTML = contact[`${lang}`][`${e.target.name}Helper`];
    }
    isContactFormValid();
};

window.handleContactChange = (e, lang) => {
    fieldValidation(e, lang);
};

window.handleContactKeyUp = (e, lang) => {
    fieldValidation(e, lang);
};

const resetCaptcha = () => {
    document.getElementById('captcha').value = "";
    contactFormData.captcha.value = undefined;
    contactFormData.captcha.valid = null;
};

const resetForm = () => {
    document.getElementById('project').checked = true;
    document.getElementById('support').checked = false;
    document.getElementById('firstname').value = "";
    document.getElementById('lastname').value = "";
    document.getElementById('email').value = "";
    document.getElementById('phone').value = "";
    document.getElementById('contact-message').value = "";
    document.getElementById('captcha').value = "";
    document.getElementById('consent').checked = false;

    contactFormData = {
        option: "project",
        firstname: {
            value: undefined,
            valid: null
        },
        lastname: {
            value: undefined,
            valid: null
        },
        email: {
            value: undefined,
            valid: null
        },
        phone: {
            value: undefined,
            valid: null
        },
        message: {
            value: undefined,
            valid: null
        },
        captcha: {
            value: undefined,
            valid: null
        },
        consent: false
    };
    isContactFormValid();
};

window.handleContactSend = async (lang) => {
    try {
        LoadingBackdropService.start();
        await axios.post(`/api/sdv/contact/send`, {
            data: {
                type: contactFormData.option,
                lastname: contactFormData.lastname.value,
                firstname: contactFormData.firstname.value,
                email: contactFormData.email.value,
                phone: contactFormData.phone.value,
                message: contactFormData.message.value,
                captcha: {
                    id: captcha.id,
                    text: contactFormData.captcha.value
                }
            },
            lang 
        });
        resetForm();
        MessageService.success(contact[`${lang}`].success);
    } catch (err) {
        resetCaptcha();
        MessageService.error(contact[`${lang}`].error);
    } finally {
        refreshCaptcha();
        LoadingBackdropService.stop();
    }
};
