import axios from '../axios';

export const getCaptcha = async (width = 200, height = 100) => {
    const response = await axios.get(`/api/captcha/${width}/${height}`);

    const captcha = {
        id: response.data.id,
        image: response.data.image,
        text: response.data.text
    };

    return captcha;
};